import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/site/src/components/episodeLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Nyheter`}</h2>
    <ul>
      <li parentName="ul">{`Sydkorea byter till Linux: `}<a parentName="li" {...{
          "href": "https://news.v.daum.net/v/20200204150508999"
        }}>{`https://news.v.daum.net/v/20200204150508999`}</a></li>
      <li parentName="ul">{`Godot får "Epic Megagrant": `}<a parentName="li" {...{
          "href": "https://godotengine.org/article/godot-engine-was-awarded-epic-megagrant"
        }}>{`https://godotengine.org/article/godot-engine-was-awarded-epic-megagrant`}</a></li>
      <li parentName="ul">{`Fedora CoreOS släppt: `}<a parentName="li" {...{
          "href": "https://fedoramagazine.org/fedora-coreos-out-of-preview/"
        }}>{`https://fedoramagazine.org/fedora-coreos-out-of-preview/`}</a></li>
    </ul>
    <h2>{`Otrevligheter`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Mycroft hotas av patenttroll: `}<a parentName="p" {...{
            "href": "https://mycroft.ai/blog/troll-hunter-mycrofts-position-on-patent-trolls/"
          }}>{`https://mycroft.ai/blog/troll-hunter-mycrofts-position-on-patent-trolls/`}</a></p>
        <ul parentName="li">
          <li parentName="ul">{`Shotwell & Gnome vs patenttroll`}</li>
        </ul>
      </li>
    </ul>
    <h2>{`Trevligheter`}</h2>
    <ul>
      <li parentName="ul">{`Space Engine: `}<a parentName="li" {...{
          "href": "http://spaceengine.org/"
        }}>{`http://spaceengine.org/`}</a></li>
      <li parentName="ul">{`Halt and Catch Fire: `}<a parentName="li" {...{
          "href": "https://www.imdb.com/title/tt2543312/"
        }}>{`https://www.imdb.com/title/tt2543312/`}</a></li>
    </ul>
    <h2>{`Utmaningar`}</h2>
    <h3>{`Alex PinePhone`}</h3>
    <ul>
      <li parentName="ul">{`Har öppnat lådan`}</li>
    </ul>
    <h3>{`Sebs Linuxäventyr`}</h3>
    <ul>
      <li parentName="ul">{`Bootade in i Windows häromdagen och blev kvar där`}</li>
      <li parentName="ul">{`Inga problem på Manjaro so far`}</li>
      <li parentName="ul">{`Nästa utmaning är dotnet core-projekt och podcastinspelning`}</li>
      <li parentName="ul">{`Crtl+r i terminalen`}<ul parentName="li">
          <li parentName="ul">{`tagging `}<inlineCode parentName="li">{`command #useful`}</inlineCode></li>
          <li parentName="ul">{`Next level cliff hanger`}</li>
        </ul></li>
    </ul>
    <h2>{`Meta`}</h2>
    <ul>
      <li parentName="ul">{`DistroN, inte distroT! Tack @hund.`}</li>
    </ul>
    <h2>{`Kontakta oss`}</h2>
    <ul>
      <li parentName="ul">{`Hemsida: `}<a parentName="li" {...{
          "href": "https://trevligmjukvara.se"
        }}>{`https://trevligmjukvara.se`}</a></li>
      <li parentName="ul">{`Mail: `}<a parentName="li" {...{
          "href": "mailto:kontakt@trevligmjukvara.se"
        }}>{`kontakt@trevligmjukvara.se`}</a></li>
      <li parentName="ul">{`Twitter: `}<a parentName="li" {...{
          "href": "https://twitter.com/trevligmjukvara"
        }}>{`@trevligmjukvara`}</a></li>
      <li parentName="ul">{`Telegram: `}<a parentName="li" {...{
          "href": "https://t.me/trevligmjukvara"
        }}>{`Trevlig Mjukvara`}</a></li>
      <li parentName="ul">{`Mastodon: `}<a parentName="li" {...{
          "href": "https://fikaverse.club/@trevligmjukvara"
        }}>{`@trevligmjukvara`}</a></li>
    </ul>
    <h2>{`Tack till`}</h2>
    <ul>
      <li parentName="ul">{`Ljudeffekter från `}<a parentName="li" {...{
          "href": "https://www.zapsplat.com/"
        }}>{`https://www.zapsplat.com/`}</a></li>
      <li parentName="ul">{`Musik från `}<a parentName="li" {...{
          "href": "https://filmmusic.io"
        }}>{`https://filmmusic.io`}</a><ul parentName="li">
          <li parentName="ul">{`"Pixelland" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"NewsSting" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Cursed Intro - Dark Dramatic Epic Trailer" av Rafael Krux`}</li>
          <li parentName="ul">{`"Beautiful World - Epic and Uplifting Motivational Trailer" av Rafael Krux`}</li>
          <li parentName="ul">{`"Mystical Sting" av Alexander Nakarada`}</li>
          <li parentName="ul">{`"Pixel Peeker Polka - Faster" av Kevin MacLeod`}</li>
          <li parentName="ul">{`Licens för alla: CC BY 4.0 (`}<a parentName="li" {...{
              "href": "http://creativecommons.org/licenses/by/4.0/"
            }}>{`http://creativecommons.org/licenses/by/4.0/`}</a>{`)`}</li>
        </ul></li>
      <li parentName="ul">{`Grafik och font i loggan:`}<ul parentName="li">
          <li parentName="ul">{`Ok-emoji: emojione version 2.2.7, `}<a parentName="li" {...{
              "href": "https://commons.wikimedia.org/wiki/File:Emojione_1F44C.svg"
            }}>{`https://commons.wikimedia.org/wiki/File:Emojione_1F44C.svg`}</a>{` (CC BY 4.0)`}</li>
          <li parentName="ul">{`Font: Sulphur Point av Dale Sattler, `}<a parentName="li" {...{
              "href": "https://fonts.google.com/specimen/Sulphur+Point"
            }}>{`https://fonts.google.com/specimen/Sulphur+Point`}</a>{` (OFL)`}</li>
        </ul></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      